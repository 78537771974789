import { AnimateBounceRight, Button } from '@dreamstack/feature-components'
import { ArrowRight } from '@dreamstack/icons'
import type { FullBlockCallToActionButtonFragment } from '@dreamstack/investors-graphql'
import { getOrCreateAnonymousId } from '@dreamstack/tracking/src/getOrCreateAnonymousId'
import { trpc } from '@dreamstack/trpc/client'
import Link from 'next/link'
import { useRouter } from 'next/router'
import type { FunctionComponent } from 'react'
import { useMemo } from 'react'
import 'twin.macro'
import { useUserIdWithLoading } from '../../../auth/src/lib/useUserIdWithLoading'
import { scrollToTarget } from '../utils/scrollToTarget'

type Color = 'black' | 'aqua'
type Variant = 'contained' | 'outlined'

export const CallToActionButtonBlock: FunctionComponent<
  React.PropsWithChildren<{
    block: FullBlockCallToActionButtonFragment
  }>
> = ({ block }) => {
  const { label, url: urlRaw, hideIcon, useNewWindow } = block
  // TODO: Find more elegant way to prevent unset link.
  const color = block.color as Color
  const variant = block.variant as Variant
  const router = useRouter()
  const { userId } = useUserIdWithLoading()
  const path = router.pathname
  const slug = router.query?.slug as string
  const isAction = urlRaw?.startsWith('?')
  const isAnkerLink = urlRaw?.startsWith('#')
  const { mutateAsync: trackButtonClickServer } =
    trpc.anonymousTracking.trackEvent.useMutation()

  const url = useMemo(() => {
    if (isAction && !isAnkerLink) {
      const url = path.replace('[slug]', slug as string)
      const queryParams = '?' + urlRaw?.split('?')[1]
      return `${url}${queryParams}`
    }
    return urlRaw
  }, [isAction, path, slug, urlRaw, isAnkerLink])

  const trackClickCallToActionButton = async () => {
    if (!url) return
    const anonymousUserId = getOrCreateAnonymousId()

    await trackButtonClickServer({
      eventName: 'buttonClick',
      userId,
      anonymousUserId,
      data: {
        buttonAction: url, // TODO: url as buttonAction is not ideal, but right now the only way to get the correct information of what the user clicked on, since label could contain a different text than the actual link
        url: router.asPath,
      },
    })
  }

  return (
    <div>
      <Link
        href={url ?? '/'}
        target={useNewWindow ? '_blank' : '_self'}
        passHref
      >
        <Button
          color={color === 'black' ? 'secondary' : 'primary'}
          variant={variant === 'outlined' ? 'outlined' : 'contained'}
          endIcon={
            !hideIcon && (
              <AnimateBounceRight>
                <ArrowRight />
              </AnimateBounceRight>
            )
          }
          onClick={(e) => {
            if (isAnkerLink) scrollToTarget(urlRaw ?? '', e)
            trackClickCallToActionButton()
          }}
        >
          {label}
        </Button>
      </Link>
    </div>
  )
}
